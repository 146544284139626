<template>
    <el-main>
        <el-form ref="form" :model="addForm" :rules="rules" label-width="140px">
            <el-form-item label="排序：" prop="sort">
                <el-input v-model="addForm.sort"></el-input>
            </el-form-item>
            <el-form-item label="广告标题：" prop="title">
                <el-input v-model="addForm.title"></el-input>
            </el-form-item>
            <el-form-item label="广告分类：" prop="classify_id">
                <el-select v-model="addForm.classify_id" placeholder="请选择">
                    <el-option v-for="item in classify_list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="广告类型：">
                <el-radio-group v-model="addForm.type">
                    <el-radio :label="1">图文广告</el-radio>
                    <el-radio :label="2">软文广告</el-radio>
                    <el-radio :label="3">视频广告</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="addForm.type == 1 ? ' 添加图片：' : addForm.type == 2 ? '软文封面' : '视频封面'" prop="picture">
                <ReadyUploadSource @getSource="val => (addForm.picture = val.path)" :path="addForm.picture"
                    @removeThis="() => (addForm.picture = '')" :showStyle="{ width: '160px', height: '100px' }">
                </ReadyUploadSource>
            </el-form-item>
            <el-form-item label="软文内容：" v-if="addForm.type == 2" prop="content">
                <RichText :richTxt="addForm.content" @soninfo="val => (addForm.content = val)"></RichText>
            </el-form-item>
            <el-form-item label="添加视频" v-if="addForm.type == 3" prop="video">
                <ReadyUploadSource @getSource="val => (addForm.video = val.path)"
                    @removeThis="() => (addForm.video = '')" :path="addForm.video" type="video"
                    :showStyle="{ width: '160px', height: '100px' }"></ReadyUploadSource>
            </el-form-item>
            <el-form-item label="展示位置：" v-if="addForm.type == 1 || addForm.type == 3">
                <el-radio-group v-model="addForm.show_location">
                    <el-radio :label="0">首页banner</el-radio>
                    <el-radio :label="1">商品分类页banner</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="跳转路径：">
                <el-radio-group v-model="addForm.goto_type">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">店铺首页</el-radio>
                    <el-radio :label="2">指定实物商品</el-radio>
                    <el-radio :label="3">指定服务商品</el-radio>
                </el-radio-group>
                <el-form-item prop="goto_name" v-if="addForm.goto_type">
                    <el-input v-model="addForm.goto_name" disabled></el-input>
                    <el-button type="primary" @click="dialogVisible = !0">选择{{ addForm.goto_type == 1 ? '店铺' :
        addForm.goto_type
            == 2 ? '商品' : '服务'
}}</el-button>
                </el-form-item>
            </el-form-item>
            <el-form-item label="展示城市：">
                <el-cascader v-model="addForm.city_id" :props="{ value: 'id', label: 'name', children: '_child' }"
                    placeholder="不填则默认全部城市" clearable filterable :options="cityList"></el-cascader>
            </el-form-item>
            <el-form-item label="上架时间：" prop="show_time">
                <el-date-picker v-model="addForm.show_time" type="date" value-format="timestamp" placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="下架时间：" prop="out_time">
                <el-date-picker v-model="addForm.out_time" type="date" value-format="timestamp" placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <el-dialog :title="addForm.goto_type == 1 ? '选择店铺' : addForm.goto_type == 2 ? '选择商品' : '选择服务'"
            :visible.sync="dialogVisible" width="52%">
            <ListComponents v-if="dialogVisible"
                :styleMethon="addForm.goto_type == 1 ? 13 : addForm.goto_type == 2 ? 11 : 12" @getListItem="getItem">
            </ListComponents>
        </el-dialog>
        <Preservation @preservation="preservation">
            <router-link v-if="id" to="/member/partnersEquity" slot="return" style="margin-right: 10px">
                <el-button>返回</el-button>
            </router-link>
        </Preservation>
    </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import ListComponents from '@/components/listComponents';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
    components: {
        ReadyUploadSource,
        RichText,
        ListComponents,
        Preservation,
    },
    data () {
        let that = this;
        function checkName (rule, value, callback) {
            if (!value) {
                callback(new Error(that.addForm.goto_type == 1 ? '请选择店铺' : that.addForm.goto_type == 2 ? '请选择商品' : '请选择服务'));
            } else {
                callback();
            }
        }
        return {
            id: '',
            addForm: {
                title: '',
                classify_id: [],
                type: 1,
                picture: '',
                show_location: 0,
                content: '',
                video: '',
                goto_type: 0,
                goto_id: '',
                city_id: '',
                show_time: '',
                out_time: '',
                sort: '',
                goto_name: '',
            },
            rules: {
                sort: {
                    required: true,
                    message: '请输入排序',
                    trigger: 'blur',
                },
                classify_id: {
                    required: true,
                    message: '请选择分类',
                    trigger: 'blur',
                },
                title: {
                    required: true,
                    message: '请输入广告标题',
                    trigger: 'blur',
                },
                content: {
                    required: true,
                    message: '请输入软文内容',
                    trigger: 'blur',
                },
                picture: {
                    required: true,
                    message: '请添加图片',
                    trigger: 'blur',
                },
                video: {
                    required: true,
                    message: '请添加视频',
                    trigger: 'blur',
                },
                show_time: {
                    required: true,
                    message: '请设置上架时间',
                    trigger: 'blur',
                },
                out_time: {
                    required: true,
                    message: '请设置下架时间',
                    trigger: 'blur',
                },
                out_time: {
                    required: true,
                    message: '请设置下架时间',
                    trigger: 'blur',
                },
                goto_name: { required: true, validator: checkName, trigger: 'blur' },
            },
            cityList: [],
            dialogVisible: !1,
            classify_list: []
        };
    },
    created () {
        this.getCity();
        this.getClassifyList()
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getInfo();
        }
    },
    methods: {
        getCity () {
            this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        for (let y in list[i]._child) delete list[i]._child[y]._child;
                    }
                    this.cityList = list;
                }
            });
        },
        getClassifyList () {
            this.$axios.post(this.$api.gateway.Ad.classify_list)
                .then(res => {
                    if (res.code === 0) {
                        let arr = []
                        res.result.map(first => {
                            if (first._child) {
                                first._child.map(second => {
                                    arr.push(...second._child)
                                })
                            }
                        })
                        this.classify_list = arr
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
        },
        getItem (val) {
            if (this.addForm.goto_type == 1) {
                this.addForm.goto_name = val.store_name;
            }
            if (this.addForm.goto_type == 2) {
                this.addForm.goto_name = val.goods_name;
            }
            if (this.addForm.goto_type == 3) {
                this.addForm.goto_name = val.service_name;
            }
            this.addForm.goto_id = val.id;
            this.dialogVisible = !1;
        },
        getInfo () {
            this.$axios.post(this.$api.gateway.Ad.info, { id: this.id }).then(res => {
                if (res.code == 0) {
                    console.log(res)
                    let info = res.result;
                    let addForm = this.addForm;
                    addForm.sort = info.sort;
                    addForm.classify_id = Number(info.classify_id)
                    addForm.type = info.type;
                    addForm.title = info.title;
                    addForm.picture = info.picture;
                    addForm.show_location = info.show_location;
                    addForm.content = info.content;
                    addForm.video = info.video;
                    addForm.goto_type = info.goto_type;
                    addForm.goto_id = info.goto_id;
                    addForm.city_id = info.city_id;
                    addForm.show_time = info.show_time * 1000;
                    addForm.out_time = info.out_time * 1000;
                    addForm.goto_name = info.goto_name;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        preservation () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let addForm = _.cloneDeep(this.addForm);
                    addForm.show_time = addForm.show_time / 1000;
                    addForm.out_time = addForm.out_time / 1000;
                    addForm.city_id = addForm.city_id[addForm.city_id.length - 1];
                    if (this.id) addForm.id = this.id;
                    if (this.addForm.classify_id) addForm.classify_id = this.addForm.classify_id;
                    if (!addForm.goto_type) delete addForm.goto_id;
                    this.$axios.post(this.id ? this.$api.gateway.Ad.edit : this.$api.gateway.Ad.add, addForm).then(res => {
                        if (res.code == 0) {
                            this.$message.success("保存成功");
                            this.$router.push("/extension/advertisement/list");
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background: #fff;
    padding-bottom: 50px;

    .el-input,
    .el-cascader {
        width: 600px;
    }
}
</style>